<template>
  <v-container style="padding: 10% 0% 10% 0%;">
  <v-row class="mt_dec--40">
    <!-- Start Single Address  -->
    <v-col lg="2" md="2" sm="12" cols="12" class="mt--40">
     
    </v-col>
    <!-- End Single Address  -->

    <!-- Start Single Address  -->
    <v-col lg="8" md="8" sm="12" cols="12" class="mt--40">
       <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center colorblue" colspan="2">
                Προϊοντα / Πόντοι
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in products"
              :key="item.fuel"
            >
              <td class="text-left">{{ item.fuel }}</td>
              <td class="text-right">{{ item.points }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <!-- End Single Address  -->

    <!-- Start Single Address  -->
    <v-col lg="1" md="1" sm="12" cols="12" class="mt--40">
     
    </v-col>
    <!-- End Single Address  -->
  </v-row>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        products: [
          {
            fuel: 'Unleaded 95',
            points: '1 πόντος/λίτρο',
          },
          {
            fuel: 'Unleaded D-Force',
            points: '2 πόντοι/λίτρο',
          },
          {
            fuel: 'Unleaded 100',
            points: '3 πόντοι/λίτρο',
          },
          {
            fuel: 'Υγραέριο κίνησης',
            points: '1 πόντος/λίτρο',
          },
          {
            fuel: 'Diesel',
            points: '1 πόντος/λίτρο',
          },
          {
            fuel: 'Diesel D-Force',
            points: '2 πόντοι/λίτρο',
          },
          {
            fuel: 'Πετρέλαιο Θέρμανσης',
            points: '0.5 πόντοι/λίτρο',
          },
          {
            fuel: 'Λιπαντικά/Υπηρεσίες',
            points: '1 πόντος/ευρώ',
          },
        ],
      }
    },
  }
</script> 

<style scoped>
.colorblue {
  background-color:#234686 !important; 
  color: white !important;
  font-size: 25px !important;
}
tr:nth-child(even){
  background-color:#e8e8e9;
}
/* tr:nth-child(odd){
  color:red;
} */
</style>