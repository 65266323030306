<template>
  <v-map
    :zoom="zoom"
    :center="initialLocation"
    :options="{ scrollWheelZoom: false }"
    style="width:100%;  height: 400px;"
  >
    <v-icondefault></v-icondefault>
    <v-tilelayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></v-tilelayer>
    <v-marker-cluster
      :options="clusterOptions"
      @clusterclick="click()"
      @ready="ready"
    >
      <v-marker
        v-for="l in this.companyStores"
        :key="l.id"
        :lat-lng="l.latlng"
        :icon="l.map_logo"
      >
        <v-popup :content="l.text"> </v-popup>
      </v-marker>
    </v-marker-cluster>
  </v-map>
</template>

<script>
import * as Vue2Leaflet from "vue2-leaflet";
import { latLng, Icon, icon } from "leaflet";
import Vue2LeafletMarkercluster from "@/components/contact/MapCluster";
import iconUrl from "@/assets/images/logo/logo.png";
import shadowUrl from "@/assets/images/logo/shadow.png";
import { mapMutations, mapActions, mapGetters } from "vuex";
import "leaflet/dist/leaflet.css";

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: iconUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
});

function rand(n) {
  let max = n + 0.1;
  let min = n - 0.1;
  return Math.random() * (max - min) + min;
}

export default {
  data() {
    let customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl, shadowUrl })
    );
    return {
      icon: customicon,
      clusterOptions: {},
      initialLocation: latLng(37.98381, 23.727539),
      zoom: 10
    };
  },
  components: {
    "v-map": Vue2Leaflet.LMap,
    "v-tilelayer": Vue2Leaflet.LTileLayer,
    "v-icondefault": Vue2Leaflet.LIconDefault,
    "v-marker": Vue2Leaflet.LMarker,
    "v-popup": Vue2Leaflet.LPopup,
    "v-marker-cluster": Vue2LeafletMarkercluster,
  },
  methods: {
    ...mapActions(["getCompanyStores"]),
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    geolocate: function() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.initialLocation = latLng(position.coords.latitude, position.coords.longitude);
        this.zoom = 12;
      });
    },
  },
  async created() {
    if (!this.companyStores) {
      // this.$loading(true);
      await this.getCompanyStores();
      // this.$loading(false);
    } else {
      await this.getCompanyStores();
    }
  },
  computed: {
    ...mapGetters(["companyStores"]),
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 };
      });
    }, 5000);
    this.geolocate();
  },
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  height: 50px !important;
  width: 40px !important;
}
</style>
